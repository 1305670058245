export function currencyFormat(num) {
    if (!num)
        return `$ 0.00`;
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function validateEmail(email) {
    if (!email || email == '') return false
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (regex.test(email)) return true
    return false
}
