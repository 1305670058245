import { runInAction, action, observable, makeObservable } from "mobx";
import { apiRequest } from "../utils/api";
import globals from "../globals";
import usuariosStore from "./UsuariosStore";

class ProductosStore {
    @observable ofertas = [];
    @observable nuevos = [];
    @observable cargandoCategorias = true;
    @observable cargandoFamilias = true;
    @observable categorias = {};
    @observable familias = [];
    familiaIdByName = {};
    @observable productos = undefined;
    @observable producto = undefined;
    @observable cargando = false;
    @observable showDownloadModal = false;
    queryController;
    queryControllerListar = [];
    queryControllerCatalogo;
    cache = {};

    constructor() {
        makeObservable(this);
        this.traerOfertas();
        this.traerNuevos();
        this.traerCategorias();
        this.traerFamilias();
    }

    traerFamilias() {
        const that = this;
        if (this.cache.familias) {
            this.familias = this.cache.familias;
            return new Promise((resolve, _) => resolve());
        }
        apiRequest('/productos/familias/listar', {}, { method: 'PUT' })
            .then(data => {
                runInAction(() => {
                    that.cargandoFamilias = false;
                    that.familias = data.data;
                    that.cache['familias'] = data.data;
                    that.familias.map(f => that.familiaIdByName[f.name.replace(/ /g, '_')] = f.id);
                });
            }).catch((err) => {
            that.cargandoFamilias = false;
            console.error(err);
        });
    }

    traerCategorias() {
        const that = this;
        if (this.cache.categorias) {
            this.categorias = this.cache.categorias;
            return new Promise((resolve, _) => resolve());
        }
        apiRequest('/productos/categorias/listar', {}, { method: 'PUT' })
            .then(data => {
                runInAction(() => {
                    that.cargandoCategorias = false;
                    that.categorias = data.data;
                    that.cache['categorias'] = data.data;
                });
            }).catch((err) => {
                that.cargandoCategorias = false;
                console.error(err);
            });
    }

    traerOfertas() {
        const that = this;
        if (this.cache.ofertas) {
            this.ofertas = this.cache.ofertas;
            return new Promise((resolve, _) => resolve());
        }
        apiRequest('/productos/listar/1', {
            resXpag: 18,
            precio_bajo: true
        }, {
            method: 'PUT'
        }).then(data => {
            runInAction(() => {
                that.ofertas = data.data;
                that.cache['ofertas'] = data.data;
            })
        }).catch((err) => {
            this.cargando = false;
            console.error(err);
        });
    }

    traerNuevos(pag = 1) {
        const that = this;
        if (this.cache.nuevos) {
            this.nuevos = this.cache.nuevos;
            return new Promise((resolve, _) => resolve());
        }
        apiRequest('/productos/listar/' + pag, {
            orden: 'C',
            ordenD: 'DESC',
            resXpag: 18
        }, {
            method: 'PUT'
        }).then(data => {
            runInAction(() => {
                that.nuevos = data.data;
                that.cache['nuevos'] = data.data;
            })
        }).catch((err) => {
            this.cargando = false;
            console.error(err);
        });
    }

    setShowDownloadModal(value) {
        this.showDownloadModal = value;
    }

    bajarCatalogo() {
        const token = usuariosStore && usuariosStore.token ? usuariosStore.token : '';
        const url = globals.WS + '/productos/catalogo?t=' + token;
        window.open(url);
    }

    bajarCatalogoPDF() {
        const token = usuariosStore && usuariosStore.token ? usuariosStore.token : '';
        const url = globals.WS + '/productos/catalogopdf?t=' + token;
        window.open(url);
    }

    @action
    listarProductos(
        categoria_id,
        familia,
        texto,
        pag = 1,
        resXpag = 18,
        order = 'A',
        orderD = 'ASC'
    ) {
        const familia_id = this.familiaIdByName[familia];
        const that = this;
        if (
            !texto &&
            this.cache['productos'] &&
            this.cache['productos'][categoria_id] &&
            this.cache['productos'][categoria_id][familia_id] &&
            this.cache['productos'][categoria_id][familia_id][pag] &&
            this.cache['productos'][categoria_id][familia_id][pag][resXpag] &&
            this.cache['productos'][categoria_id][familia_id][pag][resXpag][order] &&
            this.cache['productos'][categoria_id][familia_id][pag][resXpag][order][orderD]
        ) {
            this.productos = that.cache['productos'][categoria_id][familia_id][pag][resXpag][order][orderD];
            return new Promise((resolve, _) => resolve());
        }
        this.cargando = true;
        if (this.queryControllerListar[pag])
            this.queryControllerListar[pag].abort();
        this.queryControllerListar[pag] = new AbortController();
        return apiRequest(`/productos/listar/${pag}`, {
            orden: order,
            ordenD: orderD,
            resXpag,
            categoria_id,
            familia_id,
            texto
        }, {
            method: 'PUT'
        }, {}, this.queryControllerListar[pag]
        ).then(data => {
            runInAction(() => {
                that.cargando = false;
                that.productos = data;
            });
            if (!that.cache['productos'])
                that.cache['productos'] = {};
            if (!that.cache['productos'][categoria_id])
                that.cache['productos'][categoria_id] = {};
            if (!that.cache['productos'][categoria_id][familia_id])
                that.cache['productos'][categoria_id][familia_id] = {};
            if (!that.cache['productos'][categoria_id][familia_id][pag])
                that.cache['productos'][categoria_id][familia_id][pag] = {};
            if (!that.cache['productos'][categoria_id][familia_id][pag][resXpag])
                that.cache['productos'][categoria_id][familia_id][pag][resXpag] = {};
            if (!that.cache['productos'][categoria_id][familia_id][pag][resXpag][order])
                that.cache['productos'][categoria_id][familia_id][pag][resXpag][order] = {};
            that.cache['productos'][categoria_id][familia_id][pag][resXpag][order][orderD] = data;
            this.queryControllerListar[pag] = undefined;
        }).catch((err) => {
            runInAction(() => {
                that.cargando = false;
            });
            console.error(err);
            this.queryControllerListar[pag] = undefined;
        });
    }

    @action
    prodById(prodId) {
        this.producto = undefined;
        this.cargando = true;
        if (this.productos && this.productos.data)
            this.producto = this.productos.data.filter(p => p.id === prodId);
        if (!this.producto && this.nuevos)
            this.producto = this.nuevos.filter(p => p.id === prodId);
        if (!this.producto && this.ofertas)
            this.producto = this.ofertas.filter(p => p.id === prodId);
        if (this.producto)
            this.producto = this.producto[0];
        this.cargando = false;
        if (!this.producto) {
            this.cargando = true;
            const that = this;
            if (this.queryController)
                this.queryController.abort();
            this.queryController = new AbortController();
            apiRequest(
                `/producto/${prodId}`,
                {},
                { method: 'PUT'},
                {},
                this.queryController
            ).then(data => {
                runInAction(() => {
                    that.cargando = false;
                    that.producto = data.data[0];
                });
                this.queryController = undefined;
            }).catch((err) => {
                runInAction(() => {
                    that.cargando = false;
                });
                console.error(err);
                this.queryController = undefined;
            });
        }
    }
}
const productosStore = new ProductosStore();
export default productosStore;