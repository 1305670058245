import React from "react";
import { validateEmail } from "../../utils/utils";
import Loading from "../Loading";
import usuariosStore from "../../stores/UsuariosStore";
import Modal from 'react-bootstrap/Modal';

export default class UsuarioRegistro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            email: '',
            dni: '',
            phone: '',
            password: '',
            conf: '',
            error: '',
            guardando: false,
            registrado: false
        };
    }

    async register() {
        this.setState({error: ''});
        const {
            name,
            lastName,
            email,
            dni,
            phone,
            password,
            conf
        } = this.state;

        for (let x of ['name', 'lastName', 'email', 'dni', 'phone', 'password']) {
            if (!this.state[x]) {
                this.setState({error: {[x]: true}})
                return
            }
        }

        if (!validateEmail(email)) {
            this.setState({error: {email: true}})
            return
        }

        if (dni.length != 11) {
            this.setState({error: {dni: true}})
            return
        }

        if (phone.length < 9) {
            this.setState({error: {phone: true}})
            return
        }

        if (
            password.length < 8 ||
            !password.match(/[A-Z]+/) ||
            !password.match(/[0-9]+/)
        ) {
            this.setState({error: {password: true}})
            return
        }

        if (password != conf) {
            this.setState({ error: {conf: 'La clave y su confirmación no coinciden.'}});
            return;
        }

        this.setState({guardando: true});

        const ok = await usuariosStore.register(
            { name, lastName, email, dni, phone, password },
            'NO_REDIRECT'
        );
        if (!ok && usuariosStore.error == "Su Usuario aun no ha sido habilitado.")
            this.setState({ registrado: true, guardando: false });
        else if (usuariosStore.error)
            this.setState({ error: { msg: usuariosStore.error }, guardando: false });
        else { // Si se registro y se habilito automaticamente
           // window.location.href = `/`
           // window.location.reload();
        }
    }

    update({target}) {
        const value = target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    render() {
        const {
            name,
            lastName,
            email,
            dni,
            phone,
            password,
            conf,
            error,
            guardando,
            registrado
        } = this.state;
        return (
            <>
                <Modal.Header>
                    <h4 className="modal-title " id="">Registro de Usuario</h4>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() =>
                        usuariosStore.showRegistroModal(false)
                    }></button>
                </Modal.Header>
                <div className={"modal-body"}>
                    <div className={"login"}>
                        {!registrado &&
                            <>
                                <div className={"mb-3"}>
                                    <label className={"form-label"}> Nombre </label>
                                    <input className="form-control" type={'text'} value={name} name={'name'} onChange={(e) => {
                                        this.update(e)
                                    }}/>
                                    {error.name &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>Por favor, completa tu nombre.</h6>
                                        </div>
                                    }
                                </div>

                                <div className={"mb-3"}>
                                    <label className={"form-label"}> Apellido </label>
                                    <input className="form-control" type={'text'} value={lastName} name={'lastName'} onChange={(e) => {
                                        this.update(e)
                                    }}/>
                                    {error.lastName &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>Por favor, completa tu apellido.</h6>
                                        </div>
                                    }
                                </div>
                                <div className={"mb-3"}>
                                    <label className={"form-label"}> Numero de CUIT </label>
                                    <input className="form-control" type={'text'} value={dni} name={'dni'} onChange={(e) => {
                                        this.update(e)
                                    }}/>

                                    {error.dni &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>Ingresá tu CUIT sin guiones, sólo números.</h6>
                                        </div>
                                    }
                                </div>
                                <div className={"mb-3"}>
                                    <label className={"form-label"}> E-Mail </label>
                                    <input className="form-control" type={'text'} value={email} name={'email'} onChange={(e) => {
                                        this.update(e)
                                    }}/>

                                    {error.email &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>Chequeá que tu email esté correcto.</h6>
                                        </div>
                                    }
                                </div>

                                <div className={"mb-3"}>
                                    <label className={"form-label"}> Telefono </label>
                                    <input className="form-control" type={'text'} value={phone} name={'phone'} onChange={(e) => {
                                        this.update(e)
                                    }}/>

                                    {error.phone &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>Chequeá que tu telefono esté correcto.</h6>
                                        </div>
                                    }
                                </div>

                                <div className={"mb-3"}>
                                    <label className={"form-label"}> Contraseña </label>
                                    <input className="form-control" type={'password'} value={password} name={'password'} onChange={(e) => {
                                        this.update(e)
                                    }}/>

                                    {error.password &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>Debe contener 8 caracteres mínimo, una mayúscula y un número.</h6>
                                        </div>
                                    }
                                </div>

                                <div className={"mb-3"}>
                                    <label className={"form-label"}> Repetir Contraseña </label>
                                    <input className="form-control" type={'password'} value={conf} name={'conf'} onChange={(e) => {
                                        this.update(e)
                                    }}/>

                                    {error.conf &&
                                        <div className="user-validator px-0">
                                            <i className="fas fa-caret-up"></i>
                                            <h6>{error.conf}.</h6>
                                        </div>
                                    }
                                </div>

                                <div className={"row mt-5 d-flex justify-content-center"} style={{marginBotom: '100px'}}>
                                    {error.msg &&
                                        <div className="col-12 user-warn text-center mt-0">
                                            <p> {error.msg} </p>
                                        </div>
                                    }

                                    <div className="col-12">
                                        {!guardando &&
                                            <button
                                                className=""
                                                type="button"
                                                onClick={() => {
                                                    this.register()
                                                }}
                                            >
                                                <i className="far fa-user"></i> Crear Usuario
                                            </button>
                                        }
                                        {guardando &&
                                            <Loading/>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {registrado &&
                            <>
                                <h3>Usuario pendiente</h3>

                                <div className={"mb-3"}>
                                    <p>
                                        Tu usuario está pendiente para la aprobación. <br />
                                        La habilitación puede tardar hasta 48 hs. hábiles.
                                    </p>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }
}
