import { Link } from "react-router-dom";
import globals from "../globals";
import {useEffect, useRef, useState} from "react";
import {apiRequest} from "../utils/api";
import {runInAction} from "mobx";

export function NoIVA() {
    return (
        <div className="footer-info">
            * LOS PRECIOS NO INCLUYEN IVA.<br />
            LOS PRECIOS PUEDEN VARIAR SIN PREVIO AVISO.
        </div>
    )
}

function Footer() {
    const [state, setState] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        error: undefined,
    });
    const contactoRef = useRef();

    const sendMail = () => {
        setState({...state, error: "Enviando..."});
        fetch(
            '/sendmail.php',
            {
                method: 'POST',
                body: JSON.stringify({
                    name: state.name,
                    email: state.email,
                    phone: state.phone,
                    message: state.message
                })
            }
        ).then(data => {
            //console.log(data);
            setState({...state, error: "El mensaje se envió correctamente."});
        }).catch((err) => {
            console.error(err);
            setState({...state, error: "Hubo un error enviando el mensaje, intente nuevamente o contáctenos telefónicamente."});
        });
    }

    const scrollToContacto = () => {
        var type = window.location.hash.substring(1);
        if (type == "contacto") {
            contactoRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }
    useEffect(() => {
        scrollToContacto();
    });

    return (
        <footer className="container-fluid" id="contacto">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12">
                        <img src="/img/logo-bg-black.svg" alt={''} />
                        <p>La Tablada (1766)</p>
                        <p>La Matanza, Buenos Aires.</p>
                        <p> <i className={'fa fa-phone'} /> &nbsp; +54 11 3722 9828 </p>
                        <p>
                            <a
                                rel={'noreferrer'}
                                target="_blank"
                                href={"https://wa.me/" + globals.whatsapp}>
                                <i className={'fa-brands fa-whatsapp'} style={{color: '#fff', fontSize: '1.2em'}}/>
                                <span style={{color: '#fff'}}> &nbsp; {globals.whatsappLindo } </span>
                            </a>
                        </p>
                        <p className="bold">Horario de atención:</p>
                        <p>
                            Lunes a Jueves de 8:00 a 16:00 hs.<br />
                            Viernes de 8:00 a 13:00 hs.
                        </p>
                        {!globals.enContruccion &&
                            <ul>
                                <li><Link to={'/'}>Inicio</Link></li>
                                <li><Link to={'/nosotros'}>Nosotros</Link></li>
                                <li><Link to={'/novedades'}>Novedades</Link></li>
                                <li><Link to={'/catalogo'}>Catalogo</Link></li>
                                <li><a href="#" data-bs-target="#ModalToggle" data-bs-toggle="modal">Ingresar</a></li>

                            </ul>
                        }
                    </div>
                    <div className="col-xl-4 col-lg-7 col-md-12 col-sm-12 col-12">
                        <div className="contactForm">
                            <div className="mb-3">
                                <input className="form-control" id="name" type="text" placeholder="Tu Nombre"
                                       data-sb-validations="required"
                                       value={state.name}
                                       onChange={(e) => {
                                           setState({
                                               ...state,
                                               name: e.target.value
                                           });
                                       }}
                                />
                            </div>
                            <div className="mb-3">
                                <input className="form-control" id="email" type="email" placeholder="Correo Electrónico"
                                       data-sb-validations="required, email"
                                       value={state.email}
                                       onChange={(e) => {
                                           setState({
                                               ...state,
                                               email: e.target.value
                                           });
                                       }}
                                />
                            </div>
                            <div className="mb-3">
                                <input className="form-control" id="phone" type="text" placeholder="Teléfono"
                                       data-sb-validations="required"
                                       value={state.phone}
                                       onChange={(e) => {
                                           setState({
                                               ...state,
                                               phone: e.target.value
                                           });
                                       }}
                                />
                            </div>
                            <div className="mb-3">
                                <textarea className="form-control" id="message" type="text" placeholder="Mensaje para Districentro"
                                          data-sb-validations="required"
                                          value={state.message}
                                          onChange={(e) => {
                                              setState({
                                                  ...state,
                                                  message: e.target.value
                                              });
                                          }}
                                ></textarea>
                            </div>
                            <div className="d-grid">
                                {state.error}
                                <button className="btn btn-primary btn-lg" onClick={sendMail}>Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub text-center" ref={contactoRef}>
                <span className="bold">DistriCentro Ferretera ® 2024 </span>
                | Implementada con <a href={'https://www.openplus.com.ar'}>OpenPlus</a> - Sistema de Gestión Integral.
            </div>
        </footer>
    );
}
export default Footer;