import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import productosStore from "../stores/ProductosStore";
import {useRef} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";

function CategoriasSide(props) {
    const offCanvasRef = useRef();
    const { familia, cat, subcat, subsubcat } = useParams();
    const arbolCategorias = productosStore.categorias;
    const familias = productosStore.familias;
    let categorias = arbolCategorias;
    let URLtpl = '/catalogo/%%category%%/%%id%%';
    let URLbacktpl = '/catalogo';
    let root = true;
    if (cat && arbolCategorias[cat] && Object.keys(arbolCategorias[cat]).length > 1) {
        root = false;
        categorias = arbolCategorias[cat];
        URLtpl = `/catalogo/${cat}/%%category%%/%%id%%`;
    }
    let nombreFam = familia;
    let nombreCat = cat;
    if (subcat) nombreCat = subcat;
    if (subsubcat) nombreCat = subsubcat;

    const closeOffCanvas = () => {
        if (!offCanvasRef.current.backdrop) return;
        offCanvasRef.current.backdrop.click();
    };

    return (
        <nav className="navbar navbar-expand-lg px-0 mx-0 mx-lg-auto">
            <div className="categorias-mb mobile">
                <Navbar expand={false} className={''}>
                    <Container fluid>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`}>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#categorias-mb" aria-controls="categorias-mb" aria-expanded="false"
                                    aria-label="">
                                <h3 className="text-center">CATEGORÍAS</h3>
                            </button>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-false`}
                            className={`catalogo-side`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                            placement="end"
                            ref={offCanvasRef}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                    <Link
                                        onClick={closeOffCanvas}
                                        data-bs-toggle="offcanvas"
                                        to={'/novedades'}
                                        className={"btn categoria-principal"}
                                        style={{padding: 0}}>
                                        <h4>NOVEDADES</h4>
                                    </Link>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Header>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                    <h4>CATEGORÍAS</h4>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className={'justify-content-end flex-grow-1 pe-3'}>
                                    { familias.map(fam =>
                                        <Nav.Item key={fam.id}>
                                            <Link
                                                onClick={closeOffCanvas}
                                                data-bs-toggle="offcanvas"
                                                to={'/familias/' + fam.name.replace(/ /g, '_')}
                                                className={"btn categoria-principal " +
                                                    (fam.name.replace(/ /g, '_') === nombreFam ? 'categoria-seleccionada' : '')}>
                                                {fam.name}
                                            </Link>
                                        </Nav.Item>
                                    )}

                                    {/* Uso solo familias hasta que clasifiquemos en categorias */}
                                    { false && !root &&
                                        <Nav.Item>
                                            <Link to={URLbacktpl}
                                                  style={{textWrap: 'nowrap'}}
                                                  className={"btn categoria-principal categoria-seleccionada btn-volver"}>
                                                  <i className='fa-solid fa-chevron-left' />
                                                {'VOLVER'}
                                            </Link>
                                        </Nav.Item>
                                    }
                                    { false && !root && <h6>{cat}</h6> }
                                    {false &&
                                        <>
                                        {
                                            Object.keys(categorias).filter(c => c !== 'id').sort().map(_cat => {
                                                const category = _cat.replace('/', '%2F');
                                                return (
                                                    <Nav.Item key={categorias[_cat].id}>
                                                        <Link
                                                            to={
                                                                URLtpl
                                                                    .replace('%%category%%', category)
                                                                    .replace('%%id%%', categorias[_cat].id)
                                                            }
                                                            className={"btn categoria-principal " +
                                                                (_cat === nombreCat ? 'categoria-seleccionada' : '')}>
                                                            {_cat}
                                                            {Object.keys(categorias[_cat]).length > 1 &&
                                                                <i className='fa-solid fa-chevron-right'/>
                                                            }
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }
                                        </>
                                    }
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>

            <div className="collapse navbar-collapse col-xl-3 col-lg-4 px-0 categorias-scroll" id="categorias-mb">
                <Link
                    onClick={closeOffCanvas}
                    data-bs-toggle="offcanvas"
                    to={'/novedades'}
                    style={{padding: 0, color: (props.novedades ? 'var(--light-orange)': '#000'), textDecoration: 'none', fontSize: '1em'}}>
                    <h5 className="">NOVEDADES</h5>
                </Link>
                <h5 className="">CATEGORÍAS</h5>
                <ul className="navbar-nav">
                    {familias.map(fam => {
                        return (
                            <li className="nav-item dropdown categoria-grupo" key={fam.id}>
                                <ul className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                    style={{padding: '0px'}} aria-expanded="false">
                                    <Link
                                        to={'/familias/' + fam.name.replace(/ /g, '_')}
                                        className={"btn categoria-principal " +
                                            (fam.name.replace(/ /g, '_') === nombreFam ? 'categoria-seleccionada' : '')}>
                                        {fam.name}
                                    </Link>
                                </ul>
                            </li>
                        )})}
                </ul>
                {/* Uso solo familias hasta que clasifiquemos en categorias */}
                { false && !root && <h6>{cat}</h6> }
                { false && !root &&
                    <Link to={URLbacktpl}
                          style={{textWrap: 'nowrap'}}
                          className={"btn categoria-principal categoria-seleccionada btn-volver"}>
                          <i className='fa-solid fa-chevron-left' />
                        {' VOLVER'}
                    </Link>
                }
                {false &&
                    <ul className="navbar-nav">
                        {Object.keys(categorias).filter(c => c !== 'id').sort().map(_cat => {
                            const category = _cat.replace('/', '%2F');
                            return (
                                <li className="nav-item dropdown categoria-grupo"
                                    key={categorias[_cat].id}>
                                    <ul className="nav-link dropdown-toggle" href="#" role="button"
                                        data-bs-toggle="dropdown"
                                        style={{padding: '0px'}} aria-expanded="false">
                                        <Link
                                            to={
                                                URLtpl
                                                    .replace('%%category%%', category)
                                                    .replace('%%id%%', categorias[_cat].id)
                                            }
                                            className={"btn categoria-principal " +
                                                (_cat === nombreCat ? 'categoria-seleccionada' : '')}>
                                            {_cat}
                                            {Object.keys(categorias[_cat]).length > 1 &&
                                                <i className='fa-solid fa-chevron-right'/>
                                            }
                                        </Link>
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </nav>
    );
}
export default observer(CategoriasSide);
