import { observer } from "mobx-react";
import usuariosStore from "../stores/UsuariosStore";
import Modal from 'react-bootstrap/Modal';
import UsuarioRegistro from "./Usuario/Registro";
import UsuarioLogin from "./Usuario/Login";

function UsuarioModal() {
    return (
        <div>
            {!usuariosStore.logged &&
                    <button className="btn button-login-mb button-login" onClick={() =>
                        usuariosStore.showLoginModal(!usuariosStore.showLogin)
                    }>
                        <div className="hp-desktop">Ingresar </div><i className="fa-regular fa-user"></i>
                    </button>
            }
            {usuariosStore.logged &&
                <button className="btn btn-primary button-login desktop" onClick={() => usuariosStore.logout()}>
                    Salir ({usuariosStore.info.name}) <i className="fa-regular fa-user"></i>
                </button>
            }
            {usuariosStore.logged &&
                <button className="btn btn-primary button-login-mb mobile" onClick={() => usuariosStore.logout()}>
                    <i className="fa-regular fa-user"></i>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </button>
            }

            <Modal
                show={usuariosStore.showLogin||usuariosStore.showRegistro}
                onHide={() => {
                    if (usuariosStore.showLogin)
                        usuariosStore.showLoginModal(false)
                    if (usuariosStore.showRegistro)
                        usuariosStore.showRegistroModal(false)
                }}
                backdrop={'static'}
                keyboard={false}
                id={'login'}
            >
                {usuariosStore.showLogin &&
                    <UsuarioLogin />
                }
                { usuariosStore.showRegistro &&
                    <UsuarioRegistro />
                }
            </Modal>
        </div>
    );
}
export const Usuario = observer(UsuarioModal);