import {CarouselDesktop, CarouselMobile} from "./Carousel";

function HeaderHome() {
    return (
        <div>
            {/* Carousel */}
            <div className="container-fluid px-0 mx-0 desktop">
                <div className="row px-0 mx-0">
                    <CarouselDesktop />
                </div>
            </div>
            <div className="container-fluid px-0 mx-0 mobile">
                <div className="row px-0 mx-0">
                    <CarouselMobile />
                </div>
            </div>
            {/* Fin Carousel */}
        </div>
    );
}
export default HeaderHome;