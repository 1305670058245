import { runInAction, action, observable, makeObservable, computed, autorun } from "mobx";
import { apiRequest } from "../utils/api";

class UsuariosStore {
    @observable showLogin = false;
    @observable showRegistro = false;
    @observable info = {};
    @observable token = '';
    error = undefined;

    constructor() {
        makeObservable(this);
        this.getStoredData();
    }

    @action
    getStoredData() {
        this.token = localStorage.getItem('token');
        this.info = JSON.parse(localStorage.getItem('info'));
    }

    @computed
    get logged() {
        return !!this.token;
    }

    storeData() {
        localStorage.setItem('token', this.token);
        localStorage.setItem('info', JSON.stringify(this.info));
    }

    removeStoredData() {
        localStorage.removeItem('token');
        localStorage.removeItem('info');
    }

    @action
    showLoginModal(value) {
        console.log('showLoginModal', value);
        this.showLogin = value;
    }

    @action
    showRegistroModal(value) {
        console.log('showRegistroModal', value);
        this.showRegistro = value;
    }

    logout() {
        this.info = '';
        this.token = '';
        this.removeStoredData();
        window.location.reload();
    }

    async login(user, password) {
        const that = this;
        return await apiRequest('/usuarios/login', {user, password}, {reject: true})
            .then(data => {
                runInAction(() => {
                    that.info = data.userInformation;
                    that.token = data.token;
                    that.storeData();
                    window.location.reload();
                });
                return true;
            }).catch((err) => {
                runInAction(() => {
                    that.info = {};
                    that.token = '';
                });
                console.error(err);
                return false;
            });
    }

    @action
    async register(data, redirect = 'home') {
        const that = this;
        if (this.logged) {
            console.log('El usuario ya se encuentra ingresado.');
            this.error = 'El usuario ya se encuentra ingresado.';
            return false;
        }
        return await apiRequest('/usuarios/register', data, { reject: true })
            .then((response) => {
                runInAction(() => {
                    that.info = response.userInformation;
                    that.token = response.token;
                    that.storeData();
                    this.error = undefined;
                    return true;
                });
            }).catch((err) => {
                console.log(err);
                this.error = err;
                return false;
            });
    }
}
const usuariosStore = new UsuariosStore();
export default usuariosStore;
