import React from 'react';

export default function Redes({ nro }) {
    const style = {
        color: 'var(--light-orange)',
        fontSize: '40px',
        backgroundColor: 'transparent',
        marginLeft: '10px',
        padding: '0px',
        borderRadius: '20px'
    }
    return (
        <div style={{
            position: 'fixed',
            zIndex: '2001',
            bottom: '15px',
            left: '5px',
            cursor: 'pointer'
        }}>
            <a rel='noreferrer' target='_blank' href={'https://www.instagram.com/districentroferretera'}>
                <i className={'fa-brands fa-instagram'}
                   style={{
                       ...style,
                       padding: '0px 0px',
                       borderRadius: '10px'
                   }}
                />
            </a>
            <a rel='noreferrer' target='_blank' href={'https://www.facebook.com/profile.php?id=61562525814639'}>
                <i className={'fa-brands fa-facebook'} style={style} />
            </a>
        </div>
    )
}
