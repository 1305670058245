//import userStore from '../stores/UserStore'
import globals from "../globals";
import usuariosStore from "../stores/UsuariosStore";

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function getCDN() {
    if (Array.isArray(globals.CDN)) {
        const i = getRandomInt(globals.CDN.length);
        return globals.CDN[i];
    }
    return globals.CDN;
}

export function apiURL(endpoint) {
    const API_URL = globals.WS;
    if (!API_URL) {
        console.log('No API_URL', endpoint);
        return;
    }
    return API_URL + endpoint;
}

export function cdnURL(img, defaultImg) {
    if (!img || img === '')
        return defaultImg ?? '/fonts/producto.jpg';
    const CDN_URL = getCDN();
    return CDN_URL + img;
}

export function apiRequest(
    endpoint,
    data = {},
    opts = {},
    headers = {},
    controller
) {
    return new Promise(async (resolve, reject) => {
        let body;
        body = { ...data };

        const token = usuariosStore && usuariosStore.token ? usuariosStore.token : '';
        if (token)
            headers['x-openideas-onlinetoken'] = token;

        let url = endpoint;
        if (!url || url === 'undefined') {
            reject();
        }

        let method = opts && opts.method ? opts.method : 'PUT';

        if (!apiURL(endpoint)) {
            reject();
        }

        fetch(apiURL(url), {
            method: method,
            body: method !== 'GET' ? JSON.stringify(body) : undefined,
            headers,
            signal: controller ? controller.signal : undefined
        })
            .then((response) => {
                const ok = response.ok;
                response
                    .text()
                    .then((text) => {
                        try {
                            if (!ok) {
                                reject(text)
                            }
                            try {
                                const data = JSON.parse(text);
                                resolve(data);
                            } catch(e) {
                                resolve(data);
                            }
                        } catch (e) {
                            console.log(e);
                            if (opts.reject) reject(e);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (opts.reject) reject();
                    })
            })
            .catch((error) => {
                console.log(error);
                if (opts.reject) reject(error);
            })
    })
}
