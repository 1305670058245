import {Carousel} from "react-bootstrap";

export function CarouselDesktop() {

    return (
        <Carousel interval={2000} className="carousel  px-0 maincarousel" fade={true}>
            <Carousel.Item>
                <img alt='' src="https://cdn1-districentro.openplus.net.ar/cdn/archivos/5.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="https://cdn2-districentro.openplus.net.ar/cdn/archivos/6.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="https://cdn3-districentro.openplus.net.ar/cdn/archivos/7.png" className="d-block w-100"/>
            </Carousel.Item>
        </Carousel>
    );
}

export function CarouselMobile() {
    return (
        <Carousel interval={2500} className="carousel px-0 maincarousel" fade={true}>
            <Carousel.Item>
                <img alt='' src="https://cdn1-districentro.openplus.net.ar/cdn/archivos/8.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="https://cdn1-districentro.openplus.net.ar/cdn/archivos/9.png" className="d-block w-100"/>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='' src="https://cdn1-districentro.openplus.net.ar/cdn/archivos/10.png" className="d-block w-100"/>
            </Carousel.Item>
        </Carousel>
    );
}