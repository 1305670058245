import {Routes, Route} from 'react-router-dom';
import ProductosNuevos from "./ProductosNuevos";
import ProductosOfertas from "./ProductosOfertas";
import Nosotros from "./Nosotros";
import Buscador from "./Buscador";
import Catalogo from "./Catalogo";
import HeaderHome from "./HeaderHome";
import CarouselMarcas from "./CarouselMarcas";
import FooterHome from "./FooterHome";
import ProductoDescripcion from "./ProductoDescripcion";
import globals from "../globals";

function Body() {
    let home = (
        <div>
            <HeaderHome />
            <div className={'carousel-marcas-home '}>
                 <CarouselMarcas />
            </div>
            <div className={'row pt10 search-bar-home'}>
                <Buscador />
            </div>
            {/* Uso ofertas para mostrar los que bajaron de precio */}
            <div className="container mt-5" id="productos-ofertas">
                <ProductosOfertas />
            </div>
            <div className="container" id="productos-nuevos">
                <ProductosNuevos />
            </div>
            {false && <FooterHome />}
        </div>
    )
    if (globals.enContruccion) {
        home = (
            <div style={{
                marginTop: '100px',
                marginBottom: '100px',
                textAlign: 'center',
                fontSize: '2.5em',
                marginLeft: '50px',
                marginRight: '50px'
            }}>
                <br />
                <br />
                Estamos en Contruccion!<br/>
                Próximamente estaremos online con el catálogo mas completo de productos.
            </div>
        )
    }
    return (
        <Routes>
            <Route path={'/'} exact element={home} />
            <Route path={'/nosotros'} exact element={
                <div>
                    <Nosotros />
                    {false && <FooterHome />}
                </div>
            } />
            <Route path={'/catalogo/buscar/:query'} exact element={ <Catalogo /> } />

            <Route path={'/novedades'} exact element={ <Catalogo novedades={true} /> } />

            <Route path={'/familias/:familia'} exact element={ <Catalogo /> } />

            <Route path={'/catalogo/:cat/:subcat/:subsubcat/:catId'} exact element={ <Catalogo /> } />
            <Route path={'/catalogo/:cat/:subcat/:catId'} exact element={ <Catalogo /> } />
            <Route path={'/catalogo/:cat/:catId'} exact element={ <Catalogo /> } />
            <Route path={'/catalogo'} exact element={ <Catalogo /> } />

            <Route path={'/producto/:cat/:subcat/:subsubcat/:prodName/:prodId'} exact element={ <ProductoDescripcion /> } />
            <Route path={'/producto/:cat/:subcat/:prodName/:prodId'} exact element={ <ProductoDescripcion /> } />
            <Route path={'/producto/:cat/:prodName/:prodId'} exact element={ <ProductoDescripcion /> } />
            <Route path={'/producto/:prodName/:prodId'} exact element={ <ProductoDescripcion /> } />
        </Routes>
    );
}
export default Body;