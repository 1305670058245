import {useState} from "react";
import { useNavigate } from "react-router-dom";

function Buscador() {
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState(false);
    const navigate = useNavigate();
    let container = {
        position: 'fixed',
        top: '85px',
        zIndex: '10000',
        right: '15px',
        width: search ? '500px' : '58px',
        backgroundColor: 'var(--light-orange)',
        padding: '0px 6px 0px 0px',
        borderRadius: '40px',
        height: '58px',
        border: 'solid 0px #ccc'
    };
    return (
        <div className="search-bar mx-0 order-xl-2 order-lg-1 order-1"
            style={container}>
            <div className="container px-0">
                <div className="row buscador px-0 mx-0">
                    <div className="search-bar mx-0" style={{padding: 0}}>
                        <input
                            type="search"
                            className="form-control me-lg-2 me-0 py-3"
                            style={{
                                width: '100%',
                                display: search ? 'block' : 'none',
                                height: '58px',
                                border: 'solid 1px var(--light-orange)'
                            }}
                            id="query"
                            name="query"
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            placeholder="Buscar"
                            aria-label="Buscar"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    navigate(`/catalogo/buscar/${query}`, { replace: false });
                                    navigate(0);
                                }
                            }}
                        />
                        <button
                            className={'btn desktop btn-buscador-icono'}
                            onClick={() => {
                                setSearch(!search);
                                //navigate(`/catalogo/buscar/${query}`, { replace: false });
                                //navigate(0);
                            }}
                            style={{ cursor: 'pointer', border: 0 }}
                        >
                            <i className="fa-solid fa-magnifying-glass"
                               style={{ cursor: 'pointer', color: '#fff', fontSize: '21px' }}
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Buscador;