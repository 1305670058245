import { observer } from "mobx-react";
import Catalogo from "./Catalogo";

function ProductosNuevos() {
    return <Catalogo
        simple={true}
        titulo={'PRODUCTOS NUEVOS'}
        scroll={false}
        resXpag={9}
        novedades={true}
    />;
}
export default observer(ProductosNuevos);
