import Modal from "react-bootstrap/Modal";
import usuariosStore from "../../stores/UsuariosStore";
import { useState } from "react";
import { observer } from "mobx-react";

function UsuarioLogin() {
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [cargando, setCargando] = useState(false);
    const [error, setError] = useState('');

    const login = async () => {
        if (cargando) return;
        if ((!usuario || !clave) && !usuariosStore.logged) {
            setError('Ingrese el Usuario y la Clave');
            return;
        }
        setCargando(true);
        const ok = await usuariosStore.login(usuario, clave);
        if (!ok) {
            setError('Usuario o Clave incorrecto.');
        } else {
            setError('');
            usuariosStore.showLoginModal(false);
        }
        setCargando(false);
    };

    return (
        <>
            <Modal.Header>
                <h4 className="modal-title " id="">Ingresar al Catálogo</h4>
                <button type="button" className="btn-close" aria-label="Close" onClick={() =>
                    usuariosStore.showLoginModal(false)
                }></button>
            </Modal.Header>
            <div className="modal-body">
                <div className="login">
                    <div className="mb-3">
                        <label className="form-label">Correo electrónico</label>
                        <input
                            type="email"
                            className="form-control"
                            id="usuario"
                            name="usuario"
                            value={usuario}
                            onChange={e => setUsuario(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Contraseña</label>
                        <input
                            type="password"
                            className="form-control"
                            id="clave"
                            name="clave"
                            value={clave}
                            onChange={e => setClave(e.target.value)}
                        />
                    </div>
                    {/*
                                    <div className="mb-3 form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                        <label className="form-check-label">No soy un robot</label>
                                    </div>
                                    */}
                    <div>
                        <p>* Para poder ver el catálogo completo, debes contactarnos para registrar tu
                            usuario. <a href="mailto:info@districentroferretera.com.ar">info@districentroferretera.com.ar</a>
                        </p>
                    </div>
                    {error && <div><p className={'error'}>{error}</p></div>}
                    <button className="" onClick={login}>
                        {!cargando && "Entrar"}
                        {cargando && <i className="fa fa-spinner"></i>}
                    </button>
                    <button
                        className=""
                        style={{marginTop: '10px'}}
                        onClick={() => {
                            usuariosStore.showRegistroModal(true);
                            usuariosStore.showLoginModal(false);
                        }}>
                        Registrarse
                    </button>
                </div>
            </div>
        </>
    )
}
export default UsuarioLogin = observer(UsuarioLogin);
