import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile } from "react-device-detect";

function CarouselMarcas(props) {
    const responsive = {
        
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 10,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 576 },
            items: 6,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const marcas = [
        { alt:'UHU' ,src:"/img/marcas/marcas-01.png" },
        { alt:'Rhein' ,src:"/img/marcas/marcas-02.png" },
        { alt:'Duroll' ,src:"/img/marcas/marcas-03.png" },
        { alt:'Hunter' ,src:"/img/marcas/marcas-04.png" },
        { alt:'NTH Grilon' ,src:"/img/marcas/marcas-05.png" },
        { alt:'Valforte' ,src:"/img/marcas/marcas-06.png" },
        { alt:'Metz' ,src:"/img/marcas/marcas-07.png" },
        { alt:'Matezz' ,src:"/img/marcas/marcas-08.png" },
        { alt:'TF3' ,src:"/img/marcas/marcas-09.png" },
        { alt:'Fortex' ,src:"/img/marcas/marcas-10.png" },
        { alt:'Ideal' ,src:"/img/marcas/marcas-11.png" },
        { alt:'El Galgo' ,src:"/img/marcas/marcas-12.png" },
        { alt:'Moises' ,src:"/img/marcas/marcas-13.png" },
        { alt:'Evel' ,src:"/img/marcas/marcas-14.png" },
        { alt:'De Luca' ,src:"/img/marcas/marcas-15.png" },
        { alt:'MOR' ,src:"/img/marcas/marcas-16.png" },
        { alt:'Crecchio' ,src:"/img/marcas/marcas-17.png" },
        { alt:'BichoRed' ,src:"/img/marcas/marcas-18.png" },
        { alt:'Duke' ,src:"/img/marcas/marcas-19.png" },
        { alt:'Safari' ,src:"/img/marcas/marcas-20.png" },
        { alt:'Bruato' ,src:"/img/marcas/marcas-21.png" },
        { alt:'05 Color Plus' ,src:"/img/marcas/marcas-22.png" },
        { alt:'Brogas' ,src:"/img/marcas/marcas-23.png" }
    ];
    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="all .5s"
            transitionDuration={2500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            deviceType={isMobile ? 'mobile' : ''}
            dotListClass="custom-dot-list-style"
            itemClass="div-padding-40-px"
            className=" carousel-marcas"
        >
            { marcas.map(m => (
                <div key={m.alt}>
                    <img alt={m.alt} src={m.src} className="" />
                </div>
            ))}
        </Carousel>
    );
}
export default CarouselMarcas;


