const globals = {
    WS: "https://districentro.openplus.net.ar/api",
    CDN: [],
    enContruccion: false,
    whatsapp: '+5491122608188',
    whatsappLindo: '+54 911 2260-8188',
};
for(var c = 1; c <= 5; c++) {
    globals.CDN.push("https://cdn" + c + "-districentro.openplus.net.ar");
}

const testGlobals = {
    WS: "http://localhost:4000/api",
    CDN: [],
    enContruccion: false,
    whatsapp: '+5491122608188',
    whatsappLindo: '+54 911 2260-8188',
}
for(var c = 1; c <= 5; c++) {
    testGlobals.CDN.push("https://cdn" + c + "-districentro.openplus.net.ar");
}

export { globals as default };
//export {testGlobals as default}
