import React from 'react';

export default function Whatsapp({ nro }) {
    return (
        <div style={{
            position: 'fixed',
            zIndex: '2001',
            bottom: '15px',
            right: '15px',
            cursor: 'pointer'
        }}>
            <a rel='noreferrer' target='_blank' href={'https://wa.me/' + nro}>
                <img
                    alt={''}
                    style={{width: '60px', height: '60px'}}
                    src={'/img/whatsapp.png'}
                />
            </a>
        </div>
    )
}
