function Nosotros() {
    return (
        <div className="container-fluid px-0 mx-0">
            <div className="nosotros-header">
                <img alt='' className="desktop" src="/img/nosotros-dk.png" />
                <img alt='' className="mobile" src="/img/nosotros-mb.png" />
            </div>
            <div className="container nosotros-info">
                <div className="row px-0 mx-0">
                    <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12">
                        <h2>Districentro Ferretera</h2>
                        <p>
                            En <b>Districentro Ferretera</b>, nos enorgullecemos de ser el principal proveedor
                            integral del rubro por más de 20 años. Con una amplia experiencia en ferretería, ofrecemos
                            una selección incomparable de <b>más de 10.000 productos</b>, incluyendo <b>primeras marcas
                            </b> y satisfaciendo las necesidades de nuestros clientes en todo el país.
                        </p>
                        <p>
                            Nuestro amplio <b>depósito</b>, ubicado en la zona oeste de Gran Buenos Aires, nos permite
                            contar con un stock permanente de productos, garantizando entregas rápidas y eficientes.
                            Además, ofrecemos <b>envíos a todo el país</b>, para que pueda acceder a nuestra amplia
                            gama de productos sin importar su ubicación.
                        </p>
                        <br />
                        <h4>¡Somos su aliado estratégico en el rubro ferretero! </h4>
                    </div>
                    <div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 mt-3 mb-5 my-lg-0">
                        <img alt='' src="/img/nosotros.png" />
                    </div>
                </div>
            </div>
        </div>
);
}
export default Nosotros;