import {Link, useParams, useNavigate} from "react-router-dom";
import productosStore from "../stores/ProductosStore";

function CategoriasBreadcrumbs(props) {
    const { cat, subcat, subsubcat, familia } = useParams();
    const categorias = productosStore.categorias;
    let navigate = useNavigate();
    if (productosStore.cargandoCategorias) {
        return (
            <div className="row cargando-categorias pb-0 mb-0">
                <div className="col-12 px-4">
                    <p><i className='fa-solid fa-spinner'/> Cargando categorias</p>
                </div>
            </div>
        );
    }

    return (
        <div className="breadcrumbs ">
            {!props.noTodos && (
                <a
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        navigate('/catalogo', { replace: true });
                        navigate(0);
                    }}
                    className="btn-volver d-lg-inline"
                    href={'#'}
                >TODOS LOS PRODUCTOS </a>
            )}
            {props.volver && (
                <>
                    <a style={{cursor: 'pointer'}}
                       onClick={() => { navigate(-1) }}
                       className="btn-volver d-lg-inline"
                       href={'#'}>
                        <i className='fa-solid fa-chevron-left' /> VOLVER
                    </a>
                </>
            )}
            {(props.volver || !props.noTodos) && cat && (
                <span>|</span>
            )}
            {props.familia && ( <> {props.familia} </> )}
            {familia && (
                <>{familia.replace(/_/g, ' ')}</>
            )}
            {cat && categorias[cat] && (
                <Link to={`/catalogo/${cat}/${categorias[cat].id}`}>{cat}</Link>
            )}
            {cat && subcat && categorias[cat] && categorias[cat][subcat] &&  (
                <>
                    <i className='fa-solid fa-chevron-right' />
                    <Link to={`/catalogo/${cat}/${subcat}/${categorias[cat][subcat].id}`}>{subcat}</Link>
                </>
            )}
            {cat && subcat && subsubcat && categorias[cat] && categorias[cat][subcat] && categorias[cat][subcat][subsubcat] && (
                <>
                    <i className='fa-solid fa-chevron-right' />
                    <Link to={`/catalogo/${cat}/${subcat}/${subsubcat}/${categorias[cat][subcat][subsubcat].id}`}>{subsubcat}</Link>
                </>
            )}
        </div>
    );
}
export default CategoriasBreadcrumbs;